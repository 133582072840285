import React, { useState, useEffect } from "react";
import { Container, Row, Col } from 'reactstrap';
import Role1 from '../Images/Role1.jpeg';
import Role2 from '../Images/Role2.jpeg';
import Role3 from '../Images/Role3.jpg';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import "./Offer.css";
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

function ScrollToTopOnMount() {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [navigate]);

    return null;
}

const Icon = styled.img`
  position: absolute;
  display: inline-block;
  top: 23px;
  left: 30px;
 height:43px;
 width:'25px';

  cursor: pointer;
  z-index: 3;

    @media (max-width: 767px) {
    font-size: 36px!important;
   top: 23px;
     left: 10px;
  }
`;
const Text = styled.p`
 position: absolute;
  font-size: 21px;
  margin-left: 14px;
   top: 29px;
  left: 46px;
  cursor: pointer;

    @media (max-width: 767px) {
    font-size: 16px;
 top: 32px;
     left: 20px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: gray;
  transition: color 0.3s;

  &:hover {
    color: black;
  }
`;

const StyledParagraph = styled.p`
  margin-top: 16px;
  margin-bottom: 0;
  font-size: 24px;

    /* Media query for smaller screens */
  @media (max-width: 767px) {
    font-size: 18px;
  }

  /* Media query for medium screens */
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 16px;
  }

  /* Media query for large screens */
  @media (min-width: 992px) and (max-width: 1200px) {
    font-size: 20px;
  }


`;

const StyledLine = styled.div`
  width: 95%;
  height: 1px;
  background-color: rgb(100, 149, 237);
  margin: 8px auto;

`;

const StyledBodyText = styled.p`
    font-family: Archivo, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-align: center;
`;

const StyledTitle = styled.h2`
    font-family: Archivo, sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    text-align: center;
    text-transform: uppercase;
    margin:0;
    display:flex;
    justify-content:center;
    align-items:center;
`;

const ElementsData = [
    {
        headingText: "FREE COUNSEL",
        imgSrc: "/Assets/IMG_9963 1.png",
        bodyText: "We start by understanding your specific needs; then our experts create tailored workflows, and HR drafts a precise job description for your approval.",
        star: false
    },
    {
        headingText: "BEST FIT TALENT",
        imgSrc: "/Assets/Analyze-rafiki 1.png",
        bodyText: "We provide expertly matched talent, perfectly aligned with your unique requirements.",
        star: false
    },
    {
        headingText: "SEAMLESS ONBOARDING",
        imgSrc: "/Assets/Office management Customizable Disproportionate Illustrations _ Cuate Style 1.png",
        bodyText: "Our streamlined onboarding ensures a hassle-free start for you from day one.",
        star: false
    },
    {
        headingText: "GROWTH",
        imgSrc: "/Assets/IMG_9961 2 1.png",
        bodyText: "Now is the right time to consider further growth.",
        star: true
    }
];

const LineConnector = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items:center;
  margin: 0;
`;

const Line = styled.div`
  width: 40%; // Adjust width for the line on each side of the circle
  height: 2px;
  background-color: #000;
`;

const Circle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #000;
  margin: 0;
`;

const CardConnector = () => {
    return (
        <LineConnector>
            <Line />
            <Circle />
            <Line />
        </LineConnector>
    );
};

export const SingleElement = ({ headingText, imgSrc, bodyText, star }) => {
    return (
        <div style={{ width: "200px", height: '400px', zIndex: 10 }} className="d-flex flex-column align-items-center justify-content-start gap-1 px-0 mx-0">
            <div className="d-flex align-items-center justify-content-center p-2" style={{ width: '100%', backgroundColor: '#1B2E35', color: "#fff", border: "1px solid #000000" }}>
                <StyledTitle>
                    {/* {star &&<img src="/Assets/Star 1.svg" width="21px" height="21px" />} */}
                    {headingText}
                </StyledTitle>
            </div>
            <div style={{ width: '100%', border: "1px solid #000000" }}>
                <img src={imgSrc} alt={headingText} width="200px" height="200px" style={{ objectFit: "cover" }} />
            </div>
            <div className="mt-2" style={{ width: '90%' }}>
                <StyledBodyText>{bodyText}</StyledBodyText>
            </div>
        </div>
    );
};

const HowItWorksComponent = () => {
    const navigate = useNavigate();

    return (
        <>
<div
    style={{
        minHeight: 'calc(100vh - 235px)',
        marginTop: '15px',
        height: 'fit-content',
        overflow: 'hidden',
    }}
    className="d-flex flex-column align-items-center justify-content-center mb-5"
>
    <Container
        className="text-center d-flex align-items-center justify-content-center mt-2 mx-0 mb-0 pb-0"
        style={{
            padding: 0, // Ensure no extra padding
            margin: 0,
            width: '100%', // Ensure the container takes full width
        }}
    >
        <Row
            className="d-flex flex-row align-items-center justify-content-center p-0 m-0"
            style={{ width: '100%' }}
        >
            <Col className="text-center mt-3" xs={12}>
                <div
                    style={{
                        width: '100%',
                        position: 'relative',
                        paddingTop: '56.25%', // Maintain 16:9 aspect ratio
                        overflow: 'hidden',
                    }}
                >
 <video
        autoPlay
        loop
        muted
        playsInline
        style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
        }}
    >
        <source src="/Assets/how it works.mp4" type="video/mp4" />
        Your browser does not support the video tag.
    </video>
                </div>
            </Col>
        </Row>
    </Container>
</div>

             <p className="d-flex justify-content-end mb-5" style={{
                fontFamily: "Inter",
            fontSize: "16px",
            fontStyle: "italic",
            fontWeight: "700",
            lineHeight: "15px",
            textAlign: "center",
            textUnderlinePosition: "from-font",
            textDecorationSkipInk: "none"
            }}>Recruitment Framework &gt; &gt;
        </p>
        </>
    );
};

export default HowItWorksComponent;