import * as React from "react";
// import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Fab from '@mui/material/Fab';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
// import { Facebook, Instagram, LinkedIn  } from "@mui/icons-material";
import YouTubeIcon from '@mui/icons-material/YouTube';
import GoogleIcon from '@mui/icons-material/Google';
import { Box } from "@mui/material";
import { NavLink } from 'reactstrap';
// import { Link } from 'react-router-dom';

import { Container, Row, Col } from 'react-bootstrap';

// export default function Footer() {
//     return (
//         <>
//             {/* <Link href="https://wa.me/15517990700" target='_blank' color="inherit" sx={{ marginRight: 0.5 }} className="d-flex justify-content-end align-items-start mb-3 me-5">
//                 <Fab style={{ backgroundColor: "rgb(221, 159, 0)", }} aria-label="chat">
//                     <ChatBubbleIcon />
//                 </Fab>
//             </Link> */}
//             <Box mb={0}
//                 gutterBottom={0}
//                 component="footer"
//                 sx={{

//                     position: 'relative',
//                     marginBottom: '-7px',
//                     left: 0,
//                     width: '100%',
//                     height: '58px',

//                     // marginTop: "120px",
//                     backgroundColor: '#CDCDCD'
//                     ,
//                     p: 1,
//                     '@media (max-width: 600px)': {
//                         height: '100%',
//                     },
//                 }}
//             >
//                 <Container maxWidth="xl" sx={{height:'100%'}} className="py-0 my-0">
//                     <Grid container spacing={{ xs: 2, md: 5 }} sx={{height: '58px'}} justifyContent={{ xs: 'flex-start', sm: 'flex-start', md: 'flex-start' }} alignItems={{ xs: 'flex-end', md: 'flex-start' }}>
//                         <Grid item xs={12} sm={12} md={6} sx={{ display: 'flex', alignItems: 'center', paddingLeft: '48px', }} className="justify-content-center justify-content-md-start justify-content-lg-start justify-content-xl-start">
//                             {/* <Typography variant="body2" color="text.secondary" sx={{ fontSize: 12, marginRight: 1, marginTop: 0.5 }}>
//                             Follow Us
//                         </Typography> */}
//                             <Link href="https://wa.me/15517990700" color="inherit" target="_blank" >
//                                 <img src="/Assets/whatsapp.svg" alt="Facebook Icon" style={{ width: "30px", height: '30px', gap: "0px", opacity: '0px' }} />
//                             </Link>
//                             <Link href="https://www.facebook.com/" color="inherit" target="_blank" >
//                                 <img src="/Facebook.svg" alt="Facebook Icon" style={{ width: "37px", height: '37px', gap: "0px", opacity: '0px' }} />
//                             </Link>
//                             <Link href="https://www.instagram.com/" color="inherit" target="_blank" >
//                                 <img src="/Instagram.svg" alt="Instagram Icon" style={{ width: "37px", height: '37px', gap: "0px", opacity: '0px' }} />
//                             </Link>
//                             <Link href="https://www.tiktok.com/" color="inherit" target="_blank" >
//                                 <img src="/TikTok.svg" alt="Tiktok Icon" style={{ width: "37px", height: '37px', gap: "0px", opacity: '0px' }} />
//                             </Link>
//                             <Link href="https://www.linkedin.com/" color="inherit" target="_blank" >
//                                 <img src="/LinkedIn.svg" alt="Linkedin Icon" style={{ width: "37px", height: '37px', gap: "0px", opacity: '0px' }} />
//                             </Link>
//                             <Link href="https://www.youtube.com/" target="_blank" color="inherit">
//                                 <img src="/YouTube.svg" alt="Youtube Icon" style={{ width: "37px", height: '37px', gap: "0px", opacity: '0px' }} />
//                             </Link>
//                         </Grid>
//                         {/* <Grid item xs={12} sm={12} md={4} sx={{ display: 'flex', alignItems: 'center', paddingLeft: '48px', flexDirection: "column", gap: '0px' }} className="align-items-center align-items-md-end align-items-lg-end align-items-xl-end">
//                             <Link to="/" color="inherit" target="_blank" >
//                                 <p style={{ color: "#7C7D71", fontSize: "12px", marginTop: "-22px" }}>New Jersey, NJ7305</p>
//                             </Link>

//                         </Grid> */}
//                         <Grid item xs={12} sm={12} md={6} sx={{ display: 'flex', alignItems: 'center', paddingLeft: '48px', flexDirection: "column", gap: '0px' }} className="align-items-center align-items-md-end align-items-lg-end align-items-xl-end">
//                             <Link to="/" color="inherit" target="_blank" >
//                                 <img src="/Assets/instaRepLogo.svg" alt="Logo" style={{ width: "120px", height: '71px', gap: "0px", opacity: '0px', marginTop: "-20px" }} />
//                             </Link>
//                             {/* <p style={{ color: "#7C7D71", fontSize: "12px", marginTop: "-22px" }}>New Jersey, NJ7305</p> */}
//                         </Grid>
//                         {/* <Grid item xs={12} sm={12} md={6 }>
//                         <Box>
//                             <Typography variant="body2" color="text.secondary" align="center" sx={{ fontSize: 12, marginLeft: 1 }}>
//                                 {"Copyright © "}
//                                 {new Date().getFullYear()}
//                                 {" InstaRep. All rights reserved."}
//                             </Typography>
//                         </Box>
//                     </Grid> */}
//                     </Grid>
//                 </Container>
//             </Box>
//         </>
//     );
// }


export default function Footer() {
    return (
        <footer style={{ backgroundColor: '#CDCDCD', width: '100%', padding: '10px 0', position: 'relative' }}>
            <Container fluid style={{ height: '100%' }}>
                <Row className="h-100 align-items-center flex-column-reverse flex-sm-row flex-md-row flex-lg-row flex-xl-row gap-3 gap-sm-0 gap-md-0">
                    {/* Social Media Links */}
                    <Col xs={12} sm={6} md={6} className="d-flex align-items-center justify-content-center justify-content-md-start gap-2">
                        <a href="https://wa.me/15517990700" target="_blank" rel="noopener noreferrer">
                            <img src="/Assets/whatsapp.svg" alt="WhatsApp Icon" style={{ width: '30px', height: '30px' }} />
                        </a>
                        <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
                            <img src="/Facebook.svg" alt="Facebook Icon" style={{ width: '37px', height: '37px' }} />
                        </a>
                        <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
                            <img src="/Instagram.svg" alt="Instagram Icon" style={{ width: '37px', height: '37px' }} />
                        </a>
                        <a href="https://www.tiktok.com/" target="_blank" rel="noopener noreferrer">
                            <img src="/TikTok.svg" alt="TikTok Icon" style={{ width: '37px', height: '37px' }} />
                        </a>
                        <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">
                            <img src="/LinkedIn.svg" alt="LinkedIn Icon" style={{ width: '37px', height: '37px' }} />
                        </a>
                        <a href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer">
                            <img src="/YouTube.svg" alt="YouTube Icon" style={{ width: '37px', height: '37px' }} />
                        </a>
                    </Col>

                    {/* Logo and Address */}
                    <Col xs={12} sm={6} md={6} className="d-flex justify-content-start  align-items-center align-items-md-end flex-column">
                        <a href="/"  rel="noopener noreferrer">
                            <img src="/Assets/instaRepLogo.svg" alt="Logo" style={{ width: '120px', height: '71px', marginTop: '-30px'  }} />
                        </a>
                        <p style={{ color: '#7C7D71', fontSize: '12px', margin: '0', marginTop: '-18px' }}>New Jersey, NJ7305</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
} 